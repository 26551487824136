@import '../../utils/_variables.scss';
@import '../../utils/_mixins.scss';

.c_partner-row {
	padding: 4em 2em;
	// margin-top: 4em;
	text-align: center;
	// background-color: $greygreen;
	background-color: #fff;
	&__content {
		color: $purplesuperdark;
		@media (min-width: $break--big) {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	h3 {
		font-size: 1.2em;
		@media (min-width: $break--big) {
			width: 100%;
		}
	}
	ul {
		display: flex;
		margin: 2em 0;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		list-style: none;
		@media (min-width: $break--big) {
			margin: 3em 0;
			justify-content: space-between;
			align-items: center;
			flex-direction: unset;
		}
		li {
			margin-bottom: 1.2em;
			width: 100%;
			@media (min-width: $break--big) {
				margin: 0 2.5em;
				width: auto;
			}
			a {
				display: inline-block;
				@media (min-width: $break--med) {
					border-bottom: 3px solid transparent;
					&:hover {
						border-color: $pink;
					}
				}
				img,
				svg {
					display: block;
					max-height: 70px;
					width: auto;
					@media (min-width: $break--med) {
						// max-width: 100px;
					}
				}
				svg,
				svg path {
					fill: $purplesuperdark;
				}
				span {
					display: block;
					text-indent: -9000px;
				}
				&:focus {
					@include focusBorder($darkpurple);
				}
			}

			&.logo--nordic-partner {
				a {
					img {
						// width: auto;
						max-height: 90px;
					}
				}
				// border: 2px solid blue;
				@media (min-width: $break--med) {
					a {
						img {
							// max-height: 60px;
							// height: 70px;
						}
					}
				}
			}
		}
	}

	&__content {
	}
}
