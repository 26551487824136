@import '../../utils/_variables.scss';
@import '../../utils/_mixins.scss';

.c_image {
  margin-bottom: 3em;
  
  img {
    display: block;
    width: 100%;
  }
  figcaption {
    padding: $gutter;
    font-style: italic;
  }
  @media (min-width: $break--med) {
    figcaption {
      padding: $gutter 0;
    }
   
    
  }
  &--has-overlay {
    position: relative;
    .c_image__figcaption {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba($pink, 0.8);
      color: $yellow-2;
      font-family: $heading-font;
      font-style: normal;
      span {
        display: block;
        margin-top: 1.4em;
        text-align: right;
        font-style: italic;
      }
      @media (min-width: $break--med) {
        bottom: 3em;
        left: -$gutter;
        padding: calc(#{$gutter} * 2);
        width: 60%;
      }
      @media (min-width: $break--mega) {
        left: -5em;
      }
    }
  }
  &--align-center {
    .c_image__img-wrapper {
      display: flex;
      max-height: 700px;
      overflow: hidden;
      align-items: center;
    }
  }
}