@import './_variables.scss';
@import './mixins.scss';
@import './utils.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'aqua_grotesqueregular';
  src: url('../assets/fonts/aqua-webfont.woff2') format('woff2'),
       url('../assets/fonts/aqua-webfont.woff') format('woff'),
       url('../assets/fonts/aqua-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 16px;
}

body {
  min-height: 100%;
  font-family: $main-font;
  background-color: $purplesuperdark;
  color: #fff;
  overflow-x: hidden;
}


.g_center {
  margin: 0 auto;
  max-width: 1000px;
  @media (min-width: $break--med) {
    // padding: 0 1em;
    width: 90%;
  }
  &--text {
    max-width: 600px;
    ul,
    ol {
      padding-left: 20px;
    }
  }
}

.t_page {
  padding-bottom: 4em;
}

h1,
h2,
h3,
h4 {
  font-family: $heading-font;
}

h2 {
  margin-bottom: 1.6em;
  font-size: 2em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  margin-bottom: 0.8em;
  font-size: 1.2em;
  @media (min-width: $break--med) {
    font-size: 1.4em;
  }
}

p {
  margin-bottom: 1em;
  a {
    color: $purple;
    transition: color 100ms ease-in;
    @include hover() {
      color: $pink;
    }
    &:focus {
      outline: 2px solid $blue;
    }
  }
  &.u_sidenote {
    font-size: 0.8em;
    font-style: italic;
  }
  &.u_ingress {
    font-size: 1.2em;
  }
}

// Denne bør helt sikkert legges ut i en egen komponent
.button,
.c_button {
  padding: 1em;
  -webkit-appearance: none;
  border: none;
  font-size: 1em;
  font-family: $heading-font;
  text-decoration: none;
  transition: background-color 100ms ease-in, color 100ms ease-in;
  cursor: pointer;
  &--box-link {
    display: block;
    width: 100%;
    border: 1px solid $blue;
    transition: background-color 100ms ease-in, color 100ms ease-in;
  }
  &--dark-text {
    color: $purplesuperdark;
    background: transparent;
    @include hover() {
      // background-color: $blue;
      // color: $purplesuperdark;
    }
  }
  &--light-text {
    color: $blue;
    background: transparent;
    border: 1px solid $blue;
    &:focus {
      outline: 2px solid $pink;
      border-color: $pink;
      text-decoration: underline;
    }
    @include hover() {
      background-color: $blue;
      color: $purplesuperdark;
    }
  }
  &--turquoise-text {
    color: $turquoise;
    background: transparent;
    border: 1px solid $turquoise;
    &:focus {
      outline: 2px solid $pink;
      border-color: $pink;
      text-decoration: underline;
    }
    @include hover() {
      background-color: $turquoise;
      color: $purplesuperdark;
    }
  }
}
.c_icon-link {
  border: 1px solid red;
}

.c_cta {
  position: relative;
  padding: 1em 2em;
  text-decoration: none;
  font-family: $heading-font;
  font-weight: bold;
  transition: background-color 100ms ease-in;
  &:focus {
    outline: 3px solid $yellow;
  }
  &--blue {
    background-color: $blue;
    color: $purplesuperdark;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
      content: '';
      transition: height 100ms ease-in;
      background-color: $darkpurple;
    }
    @include hover() {
      background-color: darken($blue, 15);
      &:after {
        height: 0.5em;
      }
    }
  }
}

.c_round-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-appearance: none;
  border: none;
  font-size: 2em;
  font-family: $main-font;
  font-weight: bold;
  text-decoration: none;
}

.c_input {
  label {
  }
  input {
    padding: 0.75em 0.8em;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1em;
    font-family: $heading-font;
  }
  span.c_input-label-text {
    display: block;
    margin-bottom: 0.5em;
    font-weight: bold;
    font-family: $heading-font;
  }
}

