@import '../../utils/_variables.scss';
@import '../../utils/_mixins.scss';

.t_no-match {
    &__content {
        padding: 0 $gutter;
        h1 {
            font-size: 4em;
            text-align: center;
        }
    }
}