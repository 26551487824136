
@import '../../utils/_variables.scss';
@import '../../utils/_mixins.scss';

.t_past-events {
    &__content {
        padding: 0 $gutter;
        @media (min-width: $break--med) {
            padding: 0;
        }
    }
}