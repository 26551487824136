@import '../../utils/_variables.scss';
@import '../../utils/_mixins.scss';


.c_label-text-box {
  position: relative;
  padding: 2.5em 3em 2em $gutter;
  margin-bottom: 2.5em;
  color: $black;
  // overflow: hidden;
  @media (min-width: $break--med) {
    padding: 2.5em 2.5em 2em;
    // overflow: visible;
  }
  h3 {
    margin-bottom: 1em;
  }
  h4 {
    margin-bottom: 0.2em;
  }
  a {
    display: inline-block;
    padding: 0.5em 1em;
    margin-left: -1em;
    color: $black;
    font-weight: 700;
    font-size: 0.9em;
    transition: background-color 100ms ease-in-out;
    @include hover() {
      background-color: $yellow-2;
    }
  }
  &__label {
    position: absolute;
    left: 0;
    top: -1em;
    padding: 0.5em 2.5em 0.5em $gutter;
    background-color: $darkpurple;
    color: #fff;
    text-transform: uppercase;
    font-family: $heading-font;
    letter-spacing: 0.2em;
    @media (min-width: $break--med) {
      left: $gutter;
    }
  }
  ul {
    margin-bottom: 2em;
    list-style: none;
    li {
      .list-item-title {        
        font-family: $heading-font;
        font-weight: bold;
      }
      h4 {
        margin-bottom: 10.5m;
      }     
    }
  }
  &__important-note {
    // margin-top: -2.2em;
    background-color: $pink;
    margin-left: -$gutter;
    margin-right: calc(-2em - 2px);
    // width: 100%;
    padding: 2em 3em;
    text-align: center;
    font-size: 1.4em;
    font-family: $heading-font;
    font-weight: bold;
    box-sizing: border-box;
    span {
      display: block;
      margin: 0 auto;
      max-width: 9em;
    }
    @media (min-width: $break--med) {
      position: absolute;
      bottom: -3em;
      right: 2.5em;
      margin: 0;
    }
    @media (min-width: $break--mega) {
      bottom: 3em;
      right: -3em;
    }
  }
  &.has-important-note {
    position: relative;
    padding-bottom: 0;
    @media (min-width: $break--med) {
      padding-bottom: 5em;
      margin-bottom: 7em;
    }
    @media (min-width: $break--mega) {
      padding-bottom: 2em;
      padding-right: 20em;
    }
  }
  &--blue {
    background-color: $blue;
  }
  &--yellow {
    background-color: $yellow;
  }
  &--turquoise {
    background-color: $turquoise;
  }
  &--greygreen {
    background-color: $greygreen;
  }
}