@import '../../utils/_variables.scss';
@import '../../utils/_mixins.scss';

.t_last-year {
    background-color: $blue;
    color: $purplesuperdark;
    .c_label-text-box__label {
        background-color: $black;
    }
    .year-title {
        @media (min-width: $break--med) {
            margin: 0.8em 0 1em;
            font-size: 5em;
            text-align: center;
        }
    }
}