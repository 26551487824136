@import '../../../utils/variables';
@import '../../../utils/mixins';

.c_nav {
  position: sticky;
  top: 0;
  font-family: $heading-font;
  font-weight: bold;
  z-index: 30;
  &__main-menu {
    list-style: none;
    &__item {
      a {
        display: inline-block;
        padding: 0.4em 0.6em;
        text-decoration: none;
        color: $black;
        transition: padding 150ms ease-in-out;
        &.active {
          background: $blue;
          @media (min-width: $break--med) {
            position: relative;
            box-shadow: 0px 0.3em 0px 0px $blue;
            transition: background-color  80ms ease-in-out, box-shadow  80ms ease-in-out;
            &:hover {
              background-color: darken($blue, 15%);
              box-shadow: 0px 0.3em 0px 0px darken($blue, 15);
              &:after {
                display: none;
              }
            }
            &:before {
              position: absolute;
              bottom: -0.3em;
              height: 0.3em;
              background-color: $blue;
              left: 0;
              right: 0;
              // content: '';
            } 
          }
        }
        &:focus {
          @include focusBorder($darkpurple);
        }
      }
      &--highlighted {
        background: $yellow;
      }
    }
  }
  &__toggle-menu-button {
    display: inline-block;
    position: fixed;
    top: 0em;
    right: 0em;
    padding: 0;
    width: 5em;
    height: 2em;
    min-height: 44px;
    line-height: 2em;
    border: none;
    background: $purple;
    -webkit-appearance: none;
    z-index: 60;
    font-size: 1.2em;
    font-family: $heading-font;
    color: $black;
    font-weight: bold;
    transition: width 100ms ease-in-out;
    cursor: pointer;
    &:focus {
      @include focusBorder($darkpurple);
    }
    
    @media (min-width: $break--med) {
      display: none;
      height: 0;
    }
    &--clicked {
      width: 80%;
    }
  }
  
  @media (max-width: $break--med) {
    min-height: 44px;
    &__main-menu {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 4em 0;
      min-width: 15em;
      width: 80%;
      z-index: 50;
      background-color: $greygreen;
      background: $yellow;
      animation: removeVisibilty 200ms forwards;
      animation-delay: 250ms;
      transition: transform 200ms ease-in-out, background-color 200ms ease-in-out;
      transform: translateX(100%);
      &__item {
        display: block;
        padding-right: 2em;
        margin-bottom: 0.4em;
        text-align: right;
      }
      &--open {
        transform: translate(0, 0);
        animation: addVisibilty 200ms forwards;
      }
    }
    &--open {
      transform: translate(0, 0);
    }
  }
  @media (min-width: $break--med) {
    background-color: $greygreen;
    &__main-menu {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding: 0;
      margin: 0 auto;
      max-width: 80em;
      width: 90%;
      &__item {
        padding: 1em 0;
        padding: 0;
        margin: 0 0.3em;
        text-align: center;
        a {
          display: block;
          position: relative;
          padding: 0.8em 1em;
          height: 100%;
          &:after {
            background: $darkpurple;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 0;
            transition: height 100ms ease-in-out ;
            content: '';
          }
          &:hover {
            &:after {
              height: 0.3em;
            }
          }
        }
        &--highlighted {
          padding-right: 0;
        }
      }
    }
  }
}

@keyframes addVisibilty {
  from {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}

@keyframes removeVisibilty {
  from {
    visibility: visible;
  }
  to {
    visibility: hidden;
  }
}