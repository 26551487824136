@import '../../utils/_variables.scss';
@import '../../utils/_mixins.scss';

.c_day {
	margin-bottom: 3em;
	padding: 0 $gutter;
	@media (min-width: $break--med) {
		margin-bottom: 5em;
	}
	&__name {
		display: flex;
		padding-bottom: 0.5em;
		justify-content: space-between;
		align-items: flex-end;
		border-bottom: 1px solid $purplesuperdark;
		font-family: $heading-font;
		&__date {
			margin-bottom: -0.2em;
			font-size: 1.4em;
			@media (min-width: $break--med) {
				font-size: 3em;
			}
			&:after {
				display: inline-block;
				padding-top: 4px;
				content: 'th';
				font-size: 0.6em;
				vertical-align: top;
			}
		}
		&__day {
			text-transform: uppercase;
			@media (min-width: $break--med) {
				font-size: 1.2em;
			}
		}
	}
	&__text {
		padding-top: 1em;
	}
	&__program {
		max-width: 700px;
		ul {
			padding-left: 0;
			margin-top: 1em;
			list-style: none;
			li {
				position: relative;
				padding: 0.6em 0 0.6em 25px;
				@media (min-width: $break--med) {
					padding-left: 40px;
				}
				.program-item__type {
					display: block;
					position: absolute;
					top: 18px;
					left: 0;
					width: 15px;
					height: 6px;
					@media (min-width: $break--med) {
						width: 25px;
					}
					&--food {
						background-color: $purple;
					}
					&--talk {
						background-color: $pink;
					}
					&--social {
						background-color: $blue;
					}
					&--hacktime {
						background-color: $purplesuperdark;
					}
				}
				.program-item__time {
					display: inline-block;
					margin-right: 1em;
					width: 50px;
					text-align: center;
					font-weight: bold;
					font-family: $heading-font;
				}
				.program-item__extra-info {
					display: block;
					padding-left: 65px;
					font-style: italic;
				}
			}
		}
	}
}
