@import '../../utils/_variables.scss';
@import '../../utils/_mixins.scss';

.scroll-to-top {
  position: fixed;
  top: 15em;
  left: 2em;
}

.t_badges {
  &__choose-list {
    margin-bottom: 2em;
    text-align: center;
    // border: 1px solid purple;
    button {
      margin: 0 0.5em;
      padding-left: 2em;
      padding-right: 2em;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  &__main-content {
    padding: 0 $gutter;
    @media (min-width: $break--med) {
      padding: 0;
    }
    &__change-view {
      text-align: right;
      button {
        padding: 0.5em 0.5em 0.2em;
        margin: 0 0.5em;
        width: 60px;
        height: 60px;
        border: none;
        svg {
          display: block;
          margin: 0 auto 0.4em;
          width: 20px;
          height: 20px;
          fill: $turquoise;
          transition: fill 150ms ease-in;
        }
        span {
          display: block;
          text-transform: uppercase;
        }
        @include hover() {
          svg {
            fill: $purplesuperdark;
          }
        }
        &.c_button--active {
          background-color: $turquoise;
        }
      }
    }
    >p {
      margin: 0 auto 2em;
      max-width: 600px;
      width: 90%;
      text-align: center;
      font-family: $heading-font;
      font-size: 0.9em;
    }
  }
  
  .c_button--active {
    background-color: $blue;
    color: $purplesuperdark;
    svg {
      fill: $purplesuperdark;
    }
  }
  .page-intro {
    
    &__actions {
      text-align: center;
      &__change-view {
        text-align: right;
        
      }
      &__change-badges {
        margin-bottom: 2em;
                
      }
      .c_button--change-view,
      .c_button--change-what-to-show,
      button {
        span {
          display: block;
          text-transform: uppercase;
        }
      }
      @media (min-width: $break--med) {
        text-align: right;
        .c_button--change-view {
          margin-left: 1em;
        }
      }
    }
  }
  .badge-list {
    display: block;
    margin-top: 4em;
    padding: 0 1.5em;
    &--grid {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    @media (min-width: $break--big) {
      padding: 0;
    }
  }
}