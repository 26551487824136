// $header-font: 'ZCOOL KuaiLe', cursive;
// $header-font: 'Special Elite', cursive;

// @font-face {
//   font-family: 'aqua_grotesqueregular';
//   src: local('aqua_grotesqueregular'),
//     url('./aqua-webfont.woff2') format('woff2'),
//     url('./aqua-webfont.woff') format('woff'),
//     url('./aqua-webfont.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

$break--mini: 440px;
$break--med: 768px;
$break--big: 900px;
$break--mega: 1200px;

$purplesuperdark: #0b0323;
$black: #002233;
$purple: #a3a9ff;
$darkpurple: #704a7b;
$yellow: #ffffbf;
$yellow-2: #eff088;
$greygreen: #c4e2e8;
$blue: #4bc1da;
$turquoise: #4bdac7;
$turquoise-2: #a3fff3;
$pink: #df3692;


$main-font: 'Open Sans', sans-serif;
$heading-font: 'Fira Code', monospace;
$ariot-font: 'aqua_grotesqueregular';

$gutter: 1.5em;