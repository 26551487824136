@import '../../utils/_variables.scss';
@import '../../utils/_mixins.scss';

.c_badge {
  margin-bottom: 3em;
  padding-bottom: 3em;
  width: 100%;
  border-bottom: 1px solid $turquoise;
  color: $turquoise;
  &--list {
    @media (min-width: $break--med) {
      .c_badge__content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .c_badge__text {
          width: 65%;
        }
        .c_badge__image {
          width: 30%;
        }
      }
      &:nth-of-type(odd) {
        .c_badge__content {
          flex-direction: row-reverse;
          .c_badge__text {
            @media (min-width: $break--med) {
              padding-right: 3em;
            }
          }
        }
      }
      &:nth-of-type(even) {
        @media (min-width: $break--med) {
          .c_badge__text {
            text-align: right;
            .u_mega-bullet-list {
              li p {
                padding-left: 0;
                padding-right: 1.5em;
                &:before {
                  left: auto;
                  right: 0;
                }
              }
            }
          }
          .c_badge__image {
            margin-right: 3em;
          }
        }
      }
    }
  }
  &__text {
    
    .text__intro {
      font-family: $heading-font;
    }
    .text__title {
      margin-bottom: 0.4em;
      @media (max-width: $break--med) {
        text-align: center;
      }
      &__number {
        display: inline-block;
        margin-right: 0.7em;
      }
      @media (min-width: $break--med) {
        font-size: 2.6em;
        line-height: 1.2em;
      }
    }
  }
  &__image {
    margin-bottom: 1em;
    figure {
      font-family: $heading-font;
      text-align: center;
      img {
        width: 100%;
        @media (max-width: $break--med) {
          max-width: 300px;
        }
      }
      figcaption {
        margin-top: 0.6em;
        font-size: 2em;
      } 
    }

  }
  &__criteria {
    margin-top: 1em;
    max-height: 0;
    overflow: hidden;
    transition: max-height 250ms ease-in;
    h3 {
      margin: 1em 0;
    }
    .u_mega-bullet-list {
      li p:before {
        background-color: $turquoise;
      }
    }
    &--open {
      display: block;
      visibility: visible;
      max-height: 15em;
      max-height: 300px;
    }
  }
  &__toggle-criteria-button {
    margin-top: 1em;
    padding: 1em;
    width: 100%;
    border: 1px solid $blue;
    background-color: transparent;
    color: $blue;
    font-size: 1em;
    font-family: $heading-font;
    cursor: pointer;
    transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
    &:focus {
      // outline: 3px solid $purple;
    }
    @media (min-width: $break--med) {
      width: auto;
      &:hover {
        background-color: $blue;
        color: $purplesuperdark;
      }
    }
  }
  &--grid {
    padding-bottom: 0;
    margin-bottom: 1em;
    width: calc(50% - 1em);
    border-bottom: none;
    .c_badge__image {
      margin-bottom: 0;
      img {
        width: 60%;
      }
      figcaption {
        font-size: 1em;
        color: $pink;
      }
    }
    .c_badge__text {
      text-align: center;
      h2 {
        font-size: 1em;
      }
      .text__intro,
      .c_badge__toggle-criteria-button,
      .c_badge__criteria {
        display: none;
      }
      .text__title__number {
        display: inline-block;
        margin-right: 0.7em;
      }
    }
    @media (min-width: $break--med) {
      width: calc(33% - 1em);
    }
  }
}